.inline-embedded-entry-link {
    position: relative;
    display: inline-block;
    padding: 0 23px 0 5px;
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
    text-decoration: none;
}

.inline-embedded-entry-link:hover {
    cursor: pointer;
}

.inline-embedded-entry-link:after {
    position: absolute;
    right: 4px;
    font-family: 'bdc-icons';
    content: 'g';
}

.inline-embedded-entry-link:after:hover {
    cursor: pointer;
}

.inline-embedded-entry-link-printable {
    position: relative;
    display: inline-block;
    padding: 0 23px 0 5px;
    font: 700 16px/20px 'Proxima Nova';
    text-decoration: none;
}

.inline-embedded-entry-link-printable:after {
    position: absolute;
    right: 4px;
}
