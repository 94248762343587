.modal-bottom-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -3px 5px 0 rgba(115,115,116,0.5);
}

.modal-bottom-panel-button {
    background: linear-gradient(270deg, rgba(88,117,192,1) 0%, rgba(90,136,198,1) 100%);
    box-shadow: 1px 2px 5px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
    padding: 12px 0;
    margin: 13px 32px;
    text-align: center;
}

.modal-bottom-panel-button:hover {
    cursor: pointer;
}

.modal-bottom-panel-button-text {
    font: 500 14px/22px 'Proxima Nova';
    letter-spacing: -0.44px;
    color: rgba(255,255,255,1);
}
