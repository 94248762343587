.preview-indicator-container {
    position: fixed;
    top: 0;
    left: 0;
    color: #FFFFFF;
    max-height: 64px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    right: 0;
    padding: 18px 20px 18px 25px;
    background-color: #E65100;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24);
    z-index: 3;
}

.preview-indicator-margin {
    height: 64px;
}

.preview-indicator-message {
    font: 500 20px/28px 'Proxima Nova', sans-serif;
}
