.block-embedded-entry-link {
    position: relative;
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
    text-decoration: none;
}

.block-embedded-entry-link:hover {
    cursor: pointer;
}

.block-embedded-entry-link:after {
    position: absolute;
    right: -20px;
    font-family: 'bdc-icons';
    content: 'g';
}

.block-embedded-entry-link:after:hover {
    cursor: pointer;
}

.block-embedded-entry-link-printable {
    position: relative;
    font: 700 16px/20px 'Proxima Nova';
    text-decoration: none;
}

.block-embedded-entry-link-printable:after {
    position: absolute;
    right: -20px;
}
