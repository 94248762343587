.resource-link-container {
    position: relative;
    margin: -1px 0 0;
    background-color: white;
    border: 1px solid rgba(240,240,241,1);
}

.resource-link-container:after {
    pointer-events: none;
    font-family: 'bdc-icons';
    font-size: 15px;
    content: 'f';
    position: absolute;
    display: block;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    color: rgba(88,117,192,1);
}

.resource-link {
    display: block;
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
    text-decoration: none;
    padding: 14px 45px 13px 16px;
}

.resource-link-container-printable {
    position: relative;
    margin: -1px 0 0;
}

.resource-link-container-printable:after {
    position: absolute;
    display: block;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
}

.resource-link-printable {
    display: block;
    font: 700 16px/20px 'Proxima Nova';
    text-decoration: none;
    padding: 14px 45px 13px 16px;
}
