.app-container {
    width: 100%;
    min-height: 100vh;
    background-color: rgba(248, 248, 248, 1);
}

.main-container {
    overflow: hidden;
    max-width: 620px;
    min-width: 290px;
    height: 100%;
    padding: 15px 0 15px;
    margin: 0 auto;
}

.policy-header {
    font: 900 26px/32px 'Proxima Nova', sans-serif;
    letter-spacing: 0.18px;
    color: rgba(88,117,192,1);
    padding: 0 16px;
}

.company-logo {
    max-width: 120px;
    display: block;
    margin: 0 auto;
}

.error-message {
    background-color: red;
    color: white;
    text-align: center;
    margin: 15px;
    padding: 5px 0;
}

@media (min-width: 620px) {
    .company-logo {
        max-width: 120px;
        padding: 0 16px 0;
        display: inline-block;
    }
    .main-container {
        padding: 15px 80px 15px;
        margin: 0 auto;
    }
}

.app-container-printable {
    width: 100%;
    background-color: white;
}
