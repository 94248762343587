.search-bar-input-wrapper {
    position: relative;
    padding: 0 15px;
}

input[type=search] {
    appearance: none;
}

input[type=search]::-ms-clear {
    display: none;
    width : 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    display: none;
}

.search-bar-input {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 0 45px 0 17px;
    border: 1px solid rgba(240,240,241,1);
    border-radius: 24px;
    background-color: rgba(255,255,255,1);
    color: rgba(18,18,18,1);
    font: 18px/22px "Proxima Nova";
    letter-spacing: -0.11px;
}

.search-bar-input:focus {
    outline: none;
    border: 1px solid rgba(133,180,251,1)
}

.search-bar-clear-button {
    position: absolute;
    right: 25px;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    margin: auto 0;
}

.search-bar-clear-button:hover {
    cursor: pointer;
}

.search-bar-clear-button:hover.search-bar-clear-button:after {
    transition: opacity .3s;
    opacity: .5;
}

.search-bar-clear-button:after {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    height: 17px;
    width: 17px;
    margin-top: -8px;
    margin-left: -8px;
    font-family: 'bdc-icons';
    content: 'c';
    font-size: 17px;
    line-height: 1;
    color: rgba(26,36,47,1);
    border-radius: 50%;
    background-color: rgba(240,240,241,1);
}
