.document-link {
    position: relative;
    padding: 13px;
    display: flex;
    justify-content:space-between;
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
    box-shadow: 1px 2px 5px 0 rgba(0,0,0,0.2);
    text-decoration: none;
}

.document-link:visited {
    color: rgba(26,36,47,1);
}

.document-link-open-icon{
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
}

.document-link-content {
    font: 16px/22px 'Proxima Nova';
    color: rgba(26,36,47,1);
}

.document-link-printable {
    position: relative;
    padding: 13px 64px 13px 16px;
    display: block;
    border-radius: 8px;
    text-decoration: none;
}

.document-link-printable:visited {
    color: rgba(0, 0, 238);
}

.document-link-open-icon-printable{
    position: absolute;
    display: block;
    top: 50%;
    right: 16px;
    -webkit-transform: translateY(-50%);
    font: 700 16px/20px 'Proxima Nova';
}

.document-link-content-printable {
    font: 16px/22px 'Proxima Nova';
    color: rgba(26,36,47,1);
}
