.App_app-container__3Cmmu {
    width: 100%;
    min-height: 100vh;
    background-color: rgba(248, 248, 248, 1);
}

.App_main-container__3MlGS {
    overflow: hidden;
    max-width: 620px;
    min-width: 290px;
    height: 100%;
    padding: 15px 0 15px;
    margin: 0 auto;
}

.App_policy-header__3Krf9 {
    font: 900 26px/32px 'Proxima Nova', sans-serif;
    letter-spacing: 0.18px;
    color: rgba(88,117,192,1);
    padding: 0 16px;
}

.App_company-logo__qmfLF {
    max-width: 120px;
    display: block;
    margin: 0 auto;
}

.App_error-message__35wHx {
    background-color: red;
    color: white;
    text-align: center;
    margin: 15px;
    padding: 5px 0;
}

@media (min-width: 620px) {
    .App_company-logo__qmfLF {
        max-width: 120px;
        padding: 0 16px 0;
        display: inline-block;
    }
    .App_main-container__3MlGS {
        padding: 15px 80px 15px;
        margin: 0 auto;
    }
}

.App_app-container-printable__1Js-c {
    width: 100%;
    background-color: white;
}

.ErrorIndicator_error-indicator-container__1MGx6 {
    position: fixed;
    top: 0;
    left: 0;
    color: #A21523;
    right: 0;
    padding: 20px 20px 20px 30px;
    background-color: #FCF4F2;
    border: 1px solid #C55545;
    border-left: 8px solid #DA1D30;
    z-index: 1;
}

.ErrorIndicator_error-indicator-margin__iRDW0 {
    height: 64px;
}

.ErrorIndicator_error-indicator-message__2P1Ss:before{
    position: inline;
    content: 'e';
    top: 0;
    padding-right: 20px;
    font-family: 'bdc-icons';
    font-size: 25px;
    color: #DA1D30;
}

.ErrorIndicator_error-indicator-message__2P1Ss {
    display: flex;
    font: 500 14px/16px 'Proxima Nova', sans-serif;
    letter-spacing: 1.14px;
}

@media (max-width: 460px) {
    .ErrorIndicator_error-indicator-container__1MGx6 {
        padding: 10px 20px 10px 64px;
    }
}

.PreviewIndicator_preview-indicator-container__2y24i {
    position: fixed;
    top: 0;
    left: 0;
    color: #FFFFFF;
    max-height: 64px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    right: 0;
    padding: 18px 20px 18px 25px;
    background-color: #E65100;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.12), 0 4px 4px 0 rgba(0,0,0,0.24);
    z-index: 3;
}

.PreviewIndicator_preview-indicator-margin__1NMZW {
    height: 64px;
}

.PreviewIndicator_preview-indicator-message__25hrV {
    font: 500 20px/28px 'Proxima Nova', sans-serif;
}

.contents_h1-level__2ThGM {
    font: 900 26px/32px 'Proxima Nova', sans-serif;
    letter-spacing: 0.2px;
    color: rgba(88,117,192,1);
    margin: 23px 0 7px 0;
    padding: 0 16px;
}

.contents_h2-level__1z-Hw {
    font: 900 22px/28px 'Proxima Nova', sans-serif;
    color: rgba(106,107,111,1);
    letter-spacing: 0.1px;
    padding: 0 16px;
    margin: 15px 0 7px;
}

.contents_h1-level-printable__3mh0f {
    font: 900 26px/32px 'Proxima Nova', sans-serif;
    letter-spacing: 0.2px;
    margin: 23px 0 7px 0;
    padding: 0 16px;
}

.contents_h2-level-printable__7vz_k {
    font: 900 22px/28px 'Proxima Nova', sans-serif;
    letter-spacing: 0.1px;
    padding: 0 16px;
    margin: 15px 0 7px;
}

.contents_undefined-block__3Y0hZ {
    margin: 1px;
    padding-block: 3px;
    display: inline-block;
    border: solid 1px #ff9301;
    background-color: #fdf8ee;
}

.ImageAsset_image-asset__1X-cd {
    max-width: 100%;
    height: auto;
}

.DocumentLink_document-link__jdBO9 {
    position: relative;
    padding: 13px;
    display: flex;
    justify-content:space-between;
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
    box-shadow: 1px 2px 5px 0 rgba(0,0,0,0.2);
    text-decoration: none;
}

.DocumentLink_document-link__jdBO9:visited {
    color: rgba(26,36,47,1);
}

.DocumentLink_document-link-open-icon__1NHfE{
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
}

.DocumentLink_document-link-content__-tpgl {
    font: 16px/22px 'Proxima Nova';
    color: rgba(26,36,47,1);
}

.DocumentLink_document-link-printable__3eOMJ {
    position: relative;
    padding: 13px 64px 13px 16px;
    display: block;
    border-radius: 8px;
    text-decoration: none;
}

.DocumentLink_document-link-printable__3eOMJ:visited {
    color: rgba(0, 0, 238);
}

.DocumentLink_document-link-open-icon-printable__sM5O8{
    position: absolute;
    display: block;
    top: 50%;
    right: 16px;
    -webkit-transform: translateY(-50%);
    font: 700 16px/20px 'Proxima Nova';
}

.DocumentLink_document-link-content-printable__3M3e8 {
    font: 16px/22px 'Proxima Nova';
    color: rgba(26,36,47,1);
}

.InlineHyperlink_inline-hyperlink__1lx8A {
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
    text-decoration: none;
}

.InlineHyperlink_inline-hyperlink-printable__3SCzW {
    font: 700 16px/20px 'Proxima Nova';
    text-decoration: none;
}

.InlineEntryHyperlink_inline-entry-hyperlink__1wBEF {
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
    text-decoration: none;
}

.InlineEntryHyperlink_inline-entry-hyperlink-printable__pDg1S {
    font: 700 16px/20px 'Proxima Nova';
    text-decoration: none;
}

.InlineEmbeddedEntry_inline-embedded-entry-link__G1r98 {
    position: relative;
    display: inline-block;
    padding: 0 23px 0 5px;
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
    text-decoration: none;
}

.InlineEmbeddedEntry_inline-embedded-entry-link__G1r98:hover {
    cursor: pointer;
}

.InlineEmbeddedEntry_inline-embedded-entry-link__G1r98:after {
    position: absolute;
    right: 4px;
    font-family: 'bdc-icons';
    content: 'g';
}

.InlineEmbeddedEntry_inline-embedded-entry-link__G1r98:after:hover {
    cursor: pointer;
}

.InlineEmbeddedEntry_inline-embedded-entry-link-printable__3_K1B {
    position: relative;
    display: inline-block;
    padding: 0 23px 0 5px;
    font: 700 16px/20px 'Proxima Nova';
    text-decoration: none;
}

.InlineEmbeddedEntry_inline-embedded-entry-link-printable__3_K1B:after {
    position: absolute;
    right: 4px;
}

.BlockEntryLink_block-embedded-entry-link__Kc5M9 {
    position: relative;
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
    text-decoration: none;
}

.BlockEntryLink_block-embedded-entry-link__Kc5M9:hover {
    cursor: pointer;
}

.BlockEntryLink_block-embedded-entry-link__Kc5M9:after {
    position: absolute;
    right: -20px;
    font-family: 'bdc-icons';
    content: 'g';
}

.BlockEntryLink_block-embedded-entry-link__Kc5M9:after:hover {
    cursor: pointer;
}

.BlockEntryLink_block-embedded-entry-link-printable__2t2AT {
    position: relative;
    font: 700 16px/20px 'Proxima Nova';
    text-decoration: none;
}

.BlockEntryLink_block-embedded-entry-link-printable__2t2AT:after {
    position: absolute;
    right: -20px;
}

.BlockEntryTable_table-wrapper__BzAvf {
    overflow: hidden;
    overflow-x: auto;
    padding: 0 0 10px;
    margin: 15px 0 0;
}

.BlockEntryTable_table-wrapper__BzAvf::-webkit-scrollbar {
    -webkit-appearance: none;
}

.BlockEntryTable_table-wrapper__BzAvf::-webkit-scrollbar:horizontal {
    height: 5px;
}

.BlockEntryTable_table-wrapper__BzAvf::-webkit-scrollbar-thumb {
    border-radius: 2.5px;
    background-color: #9D9D9D;
}

.BlockEntryTable_table-wrapper-print__QLMTl {
    padding: 0 0 10px;
    margin: 15px 0 0;
}

.BlockEntryTable_table__1v_i- {
    table-layout: fixed;
    border-collapse: collapse;
}

.BlockEntryTable_table-row-cell__1h39_ td {
    white-space: nowrap;
}

.BlockEntryTable_table-row-cell-print__3KjFU td {
    white-space: normal;
}

.BlockEntryTable_table-row__O46PS td, th {
    border: 1px solid #777;
    padding: 10px;
    text-align: unset;
    font-weight: unset;
}

.block-ul ul li {
    white-space: pre-line;
    -ms-word-break: break-all;
    overflow-wrap: break-word;
}

.block-ul {
    padding-inline-start: 13px;
    padding-left: 13px;
}

.block-ul ul ul li:before {
    position: absolute;
    content: "\25A0";
    font-weight: bold;
    font-size: 7px;
    left: -13px;
    top: 9px;
}

.block-ul li:before {
    position: absolute;
    content: "\2022";
    font-weight: bold;
    left: -13px;
}

.block-ul ul li:before {
    position: absolute;
    content: "\26AC";
    font-weight: bold;
    font-size: 8px;
    left: -13px;
    top: 7px;
}

.block-ul li p {
    margin: 0;
    line-height: 1.5rem;
}

@media screen and (min-device-width: 460px) {
    .block-ul {
        padding-inline-start: 23px;
        padding-left: 23px;
     }
}

.block-ol ol li {
    white-space: pre-line;
    -ms-word-break: break-all;
    overflow-wrap: break-word;
}

.block-ol li p {
    display: inline;
    line-height: 1.5rem;
}

.block-ol li > ol li {
    list-style-type: upper-alpha;

}
.block-ol li > ol li > ol li {
    list-style-type: lower-roman;
}

.block-ol li > ol li > ol li > ol li {
    list-style-type: lower-alpha;
}

@media screen and (min-device-width: 460px) {
    .block-ol {
        padding-inline-start: 23px;
        padding-left: 23px;
     }
}

.CodeMark_negative__2-Qrn,
.CodeMark_warning__1BqJr,
.CodeMark_positive__1WVAn,
.CodeMark_info__2Sm8f,
.CodeMark_printable-negative__1wCY0,
.CodeMark_printable-warning__3KKVu,
.CodeMark_printable-positive__1mKsr,
.CodeMark_printable-info__mNQRE {
    margin-top: 10px;
    display: flex;
    border-radius: 7px; 
    padding: 6px;
    text-align: left;
    width: 95%;
}

@media (min-width: 620px) {
    .CodeMark_negative__2-Qrn,
    .CodeMark_warning__1BqJr,
    .CodeMark_positive__1WVAn,
    .CodeMark_info__2Sm8f,
    .CodeMark_printable-negative__1wCY0,
    .CodeMark_printable-warning__3KKVu,
    .CodeMark_printable-positive__1mKsr,
    .CodeMark_printable-info__mNQRE {
        width: -moz-fit-content;
        width: fit-content;
    }
}

.CodeMark_negative-text__1Y5et,
.CodeMark_warning-text__E3FYm,
.CodeMark_positive-text__e737W,
.CodeMark_info-text__1FHmt,
.CodeMark_printable-negative-text__No2Ud,
.CodeMark_printable-warning-text__1-3FH,
.CodeMark_printable-positive-text__24hGh,
.CodeMark_printable-info-text__3XmxT {
    display: flex;
    margin: auto;
    width: 100%;
    text-align: left;
    padding: 5px 0px;
}

.CodeMark_negative__2-Qrn:before,
.CodeMark_warning__1BqJr:before,
.CodeMark_positive__1WVAn:before,
.CodeMark_info__2Sm8f:before {
    display: flex;
    position: relative;
    margin: auto;
    margin-top: 5px;
    height: 100%;
    padding: 0px 15px 0 10px;
    font-family: 'bdc-icons';
    font-size: 20px;
}

.CodeMark_negative__2-Qrn {
    background: #FCF4F2; 
    box-shadow:
    0 0 0 1px #F3DBD7,
    0 0 0 2px #D1786B
}

.CodeMark_negative-text__1Y5et {
    color: #7A490A;
}

.CodeMark_negative__2-Qrn:before {
    content: 'e';
    color: #DA1D30;
}

.CodeMark_warning__1BqJr {
    background: #FDF8EE; 
    box-shadow:
    0 0 0 1px #FDE8C9,
    0 0 0 2px #FFA934
}

.CodeMark_warning-text__E3FYm {
    color: #86581D;
}

.CodeMark_warning__1BqJr:before {
    content: 'i';
    color: #FE9851;
}

.CodeMark_positive__1WVAn {
    background: #F3F9EA; 
    box-shadow:
    0 0 0 1px #DAF3CB,
    0 0 0 2px #77DD52
}

.CodeMark_positive-text__e737W {
    color: #637944;
}

.CodeMark_positive__1WVAn:before {
    content: 'd';
    color: #68D83F;
}

.CodeMark_info__2Sm8f {
    background: #E7EDFC;
    box-shadow:
    0 0 0 1px #D1DAF3,
    0 0 0 2px #7A91CD
}

.CodeMark_info-text__1FHmt {
    color: #64658F;
    padding: 5px 10px;
}
.RichTextContent_rich-text-container__1bTJR {
    font: 16px 'Proxima Nova', sans-serif;
    letter-spacing: -0.2px;
    padding: 1px 16px;
}

.RichTextContent_rich-text-container-background__10Wiy {
    background-color: white;
    border: 1px solid rgba(240,240,241,1);
}

.RichTextContent_rich-text-container__1bTJR ul {
    list-style: none;
}

.RichTextContent_rich-text-container__1bTJR ul li {
    position: relative;
}

.RichTextContent_rich-text-container__1bTJR ul li > ol > li:nth-of-type(n):before{
    content: "";
}

.RichTextContent_rich-text-container__1bTJR ol li > ul > li:nth-of-type(n){
    list-style:none;
}

.RichTextContent_rich-text-container-background-printable__vjP_r {
    background-color:white;
}

.HtmlContent_html-content__z-328 {
    padding: 8px 16px;
    font: 16px 'Proxima Nova', sans-serif;
    letter-spacing: -0.2px;
}

.ResourceLink_resource-link-container__3oJN4 {
    position: relative;
    margin: -1px 0 0;
    background-color: white;
    border: 1px solid rgba(240,240,241,1);
}

.ResourceLink_resource-link-container__3oJN4:after {
    pointer-events: none;
    font-family: 'bdc-icons';
    font-size: 15px;
    content: 'f';
    position: absolute;
    display: block;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    color: rgba(88,117,192,1);
}

.ResourceLink_resource-link__2Vqa1 {
    display: block;
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
    text-decoration: none;
    padding: 14px 45px 13px 16px;
}

.ResourceLink_resource-link-container-printable__rF9sP {
    position: relative;
    margin: -1px 0 0;
}

.ResourceLink_resource-link-container-printable__rF9sP:after {
    position: absolute;
    display: block;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
}

.ResourceLink_resource-link-printable__2fL6_ {
    display: block;
    font: 700 16px/20px 'Proxima Nova';
    text-decoration: none;
    padding: 14px 45px 13px 16px;
}

.TopPanel_modal-top-panel__2TEKa {
    padding: 23px 53px;
    background: linear-gradient(270deg, rgba(81,104,179,1) 0%, rgba(57,82,149,1) 100%);
}

.TopPanel_modal-top-panel-text__1zijC {
    font: 500 16px/19px 'Proxima Nova';
    letter-spacing: -0.15px;
    color: rgba(255,255,255,1);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.TopPanel_modal-top-panel-button__3PAwe {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
}

.TopPanel_modal-top-panel-button__3PAwe:hover {
    cursor: pointer;
}

.TopPanel_modal-top-panel-button__3PAwe:after {
    font-family: 'bdc-icons';
    content: 'c';
    color: rgba(255,255,255,1);
    font-size: 55px;
}

.BottomPanel_modal-bottom-panel__2fC8p {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -3px 5px 0 rgba(115,115,116,0.5);
}

.BottomPanel_modal-bottom-panel-button__M5h_T {
    background: linear-gradient(270deg, rgba(88,117,192,1) 0%, rgba(90,136,198,1) 100%);
    box-shadow: 1px 2px 5px 0 rgba(0,0,0,0.2);
    border-radius: 20px;
    padding: 12px 0;
    margin: 13px 32px;
    text-align: center;
}

.BottomPanel_modal-bottom-panel-button__M5h_T:hover {
    cursor: pointer;
}

.BottomPanel_modal-bottom-panel-button-text__EBpQT {
    font: 500 14px/22px 'Proxima Nova';
    letter-spacing: -0.44px;
    color: rgba(255,255,255,1);
}

.ContentModal_modal-overlay__19nGC {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    justify-content: center;
    align-content: space-evenly;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
}

.ContentModal_modal-container__3sxlj {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 16px;
    bottom: 16px;
    max-width: 660px;
    background-color: #ffffff;
    display: inline-block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.ContentModal_modal-main-content-container__3cI4O {
    position: absolute;
    bottom: 72px;
    top: 64px;
    left: 0;
    right: 0;
}

.ContentModal_modal-policy-content__3XxeQ {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

@media (max-width: 628px) {
    .ContentModal_modal-container__3sxlj {
        left: 16px;
        right: 16px;
        top: 16px;
        bottom: 16px;
    }
}

@media (min-width: 628px) {
    .ContentModal_modal-container__3sxlj {
        width: 100%;
        left: 16px;
        right: 16px;
        top: 16px;
        bottom: 16px;
    }
}

.SearchBarInput_search-bar-input-wrapper__3bdB9 {
    position: relative;
    padding: 0 15px;
}

input[type=search] {
    -webkit-appearance: none;
            appearance: none;
}

input[type=search]::-ms-clear {
    display: none;
    width : 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    display: none;
}

.SearchBarInput_search-bar-input__qSBMs {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 0 45px 0 17px;
    border: 1px solid rgba(240,240,241,1);
    border-radius: 24px;
    background-color: rgba(255,255,255,1);
    color: rgba(18,18,18,1);
    font: 18px/22px "Proxima Nova";
    letter-spacing: -0.11px;
}

.SearchBarInput_search-bar-input__qSBMs:focus {
    outline: none;
    border: 1px solid rgba(133,180,251,1)
}

.SearchBarInput_search-bar-clear-button__4iNYe {
    position: absolute;
    right: 25px;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    margin: auto 0;
}

.SearchBarInput_search-bar-clear-button__4iNYe:hover {
    cursor: pointer;
}

.SearchBarInput_search-bar-clear-button__4iNYe:hover.SearchBarInput_search-bar-clear-button__4iNYe:after {
    transition: opacity .3s;
    opacity: .5;
}

.SearchBarInput_search-bar-clear-button__4iNYe:after {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    height: 17px;
    width: 17px;
    margin-top: -8px;
    margin-left: -8px;
    font-family: 'bdc-icons';
    content: 'c';
    font-size: 17px;
    line-height: 1;
    color: rgba(26,36,47,1);
    border-radius: 50%;
    background-color: rgba(240,240,241,1);
}

.ItemList_search-bar-result-item__1lt1d {
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ItemList_search-bar-result-item__1lt1d:hover {
    cursor: pointer;
    background-color: rgba(247,249,252,1);
}

.ItemList_search-bar-result-item__1lt1d:not(:last-child) {
    border-bottom: 1px solid rgba(240,240,241,1);
}

.ItemList_search-bar-result-item-text__2tqd3 {
    font: 400 14px/18px "Proxima Nova", sans-serif;
    color: rgba(88,117,192,1);
    letter-spacing: 0.14px;
    vertical-align: middle;
}

.SearchBar_search-bar-container__13ACs {
    position: relative;
}

.SearchBar_search-bar-result-container__iidrU {
    position: absolute;
    top: 55px;
    background-color: rgba(255,255,255,1);
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(147,147,147,0.5);
    z-index: 1;
}

.SearchBar_search-bar-hint__3vMYU {
    padding: 0 16px;
    font: 16px/18px 'Proxima Nova', sans-serif;
    letter-spacing: -0.11px;
}

input:-ms-input-placeholder {
    color: #999;
}


* {
    font-family: 'Proxima Nova', Arial, sans-serif;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
}

div[id="root"] {
    min-height: 100vh;
}

@font-face {
    font-family: "bdc-icons";
    src:url(/static/media/bdc-icons.964a2e51.eot);
    src:url(/static/media/bdc-icons.964a2e51.eot?#iefix) format("embedded-opentype"),
    url(/static/media/bdc-icons.df20fb48.woff) format("woff"),
    url(/static/media/bdc-icons.b8f8e44a.ttf) format("truetype"),
    url(/static/media/bdc-icons.5ab423a8.svg#bdc-icons) format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    src: url(/static/media/ProximaNova-Thin.b4f889c9.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-Thin.025200cf.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 100;
    font-style: normal;
}

@font-face {
    src: url(/static/media/ProximaNova-ThinIt.a9cf5d3f.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-ThinItalic.55b0505c.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 100;
    font-style: italic;
}

@font-face {
    src: url(/static/media/ProximaNova-Light.41daf62e.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-Light.0e7d74f0.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 300;
    font-style: normal;
}

@font-face {
    src: url(/static/media/ProximaNova-LightIt.9ca09464.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-LightItalic.a53f693d.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 300;
    font-style: italic;
}

@font-face {
    src: url(/static/media/ProximaNova-Regular.72a24b99.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-Regular.1b82a5de.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: normal;
}

@font-face {
    src: url(/static/media/ProximaNova-RegularIt.277b541e.ttf) format('truetype');
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: italic;
}

@font-face {
    src: url(/static/media/ProximaNova-Medium.7cd57344.ttf) format('truetype');
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-style: normal;
}

@font-face {
    src: url(/static/media/ProximaNova-MediumIt.5f080000.ttf) format('truetype');
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-style: italic;
}

@font-face {
    src: url(/static/media/ProximaNova-Semibold.1d2739b5.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-Semibold.17fa6bca.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-style: normal;
}

@font-face {
    src: url(/static/media/ProximaNova-SemiboldIt.15d8e121.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-SemiboldIt.8977ed27.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-style: italic;
}

@font-face {
    src: url(/static/media/ProximaNova-Bold.3304de7e.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-Bold.e0373294.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-style: normal;
}

@font-face {
    src: url(/static/media/ProximaNova-BoldIt.9b7998c5.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-BoldItalic.534125bb.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-style: italic;
}

@font-face {
    src: url(/static/media/ProximaNova-Extrabold.e3757327.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-Extrabold.82b9133c.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 800;
    font-style: normal;
}

@font-face {
    src: url(/static/media/ProximaNova-ExtraboldIt.bda7b964.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-ExtraboldIt.b8336fa9.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 800;
    font-style: italic;
}

@font-face {
    src: url(/static/media/ProximaNova-Black.1c000002.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-Black.58a11f4e.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 900;
    font-style: normal;
}

@font-face {
    src: url(/static/media/ProximaNova-BlackIt.1dcad129.ttf) format('truetype');
    src: url(/static/media/ProximaNovaW08-BlackItalic.f81ec199.woff) format('woff');
    font-family: 'Proxima Nova';
    font-weight: 900;
    font-style: italic;
}

