.negative,
.warning,
.positive,
.info,
.printable-negative,
.printable-warning,
.printable-positive,
.printable-info {
    margin-top: 10px;
    display: flex;
    border-radius: 7px; 
    padding: 6px;
    text-align: left;
    width: 95%;
}

@media (min-width: 620px) {
    .negative,
    .warning,
    .positive,
    .info,
    .printable-negative,
    .printable-warning,
    .printable-positive,
    .printable-info {
        width: fit-content;
    }
}

.negative-text,
.warning-text,
.positive-text,
.info-text,
.printable-negative-text,
.printable-warning-text,
.printable-positive-text,
.printable-info-text {
    display: flex;
    margin: auto;
    width: 100%;
    text-align: left;
    padding: 5px 0px;
}

.negative:before,
.warning:before,
.positive:before,
.info:before {
    display: flex;
    position: relative;
    margin: auto;
    margin-top: 5px;
    height: 100%;
    padding: 0px 15px 0 10px;
    font-family: 'bdc-icons';
    font-size: 20px;
}

.negative {
    background: #FCF4F2; 
    box-shadow:
    0 0 0 1px #F3DBD7,
    0 0 0 2px #D1786B
}

.negative-text {
    color: #7A490A;
}

.negative:before {
    content: 'e';
    color: #DA1D30;
}

.warning {
    background: #FDF8EE; 
    box-shadow:
    0 0 0 1px #FDE8C9,
    0 0 0 2px #FFA934
}

.warning-text {
    color: #86581D;
}

.warning:before {
    content: 'i';
    color: #FE9851;
}

.positive {
    background: #F3F9EA; 
    box-shadow:
    0 0 0 1px #DAF3CB,
    0 0 0 2px #77DD52
}

.positive-text {
    color: #637944;
}

.positive:before {
    content: 'd';
    color: #68D83F;
}

.info {
    background: #E7EDFC;
    box-shadow:
    0 0 0 1px #D1DAF3,
    0 0 0 2px #7A91CD
}

.info-text {
    color: #64658F;
    padding: 5px 10px;
}