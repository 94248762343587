.h1-level {
    font: 900 26px/32px 'Proxima Nova', sans-serif;
    letter-spacing: 0.2px;
    color: rgba(88,117,192,1);
    margin: 23px 0 7px 0;
    padding: 0 16px;
}

.h2-level {
    font: 900 22px/28px 'Proxima Nova', sans-serif;
    color: rgba(106,107,111,1);
    letter-spacing: 0.1px;
    padding: 0 16px;
    margin: 15px 0 7px;
}

.h1-level-printable {
    font: 900 26px/32px 'Proxima Nova', sans-serif;
    letter-spacing: 0.2px;
    margin: 23px 0 7px 0;
    padding: 0 16px;
}

.h2-level-printable {
    font: 900 22px/28px 'Proxima Nova', sans-serif;
    letter-spacing: 0.1px;
    padding: 0 16px;
    margin: 15px 0 7px;
}

.undefined-block {
    margin: 1px;
    padding-block: 3px;
    display: inline-block;
    border: solid 1px #ff9301;
    background-color: #fdf8ee;
}
