.block-ol ol li {
    white-space: pre-line;
    -ms-word-break: break-all;
    overflow-wrap: break-word;
}

.block-ol li p {
    display: inline;
    line-height: 1.5rem;
}

.block-ol li > ol li {
    list-style-type: upper-alpha;

}
.block-ol li > ol li > ol li {
    list-style-type: lower-roman;
}

.block-ol li > ol li > ol li > ol li {
    list-style-type: lower-alpha;
}

@media screen and (min-device-width: 460px) {
    .block-ol {
        padding-inline-start: 23px;
        padding-left: 23px;
     }
}
