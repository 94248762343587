.table-wrapper {
    overflow: hidden;
    overflow-x: auto;
    padding: 0 0 10px;
    margin: 15px 0 0;
}

.table-wrapper::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-wrapper::-webkit-scrollbar:horizontal {
    height: 5px;
}

.table-wrapper::-webkit-scrollbar-thumb {
    border-radius: 2.5px;
    background-color: #9D9D9D;
}

.table-wrapper-print {
    padding: 0 0 10px;
    margin: 15px 0 0;
}

.table {
    table-layout: fixed;
    border-collapse: collapse;
}

.table-row-cell td {
    white-space: nowrap;
}

.table-row-cell-print td {
    white-space: normal;
}

.table-row td, th {
    border: 1px solid #777;
    padding: 10px;
    text-align: unset;
    font-weight: unset;
}
