.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    justify-content: center;
    align-content: space-evenly;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
}

.modal-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 16px;
    bottom: 16px;
    max-width: 660px;
    background-color: #ffffff;
    display: inline-block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.modal-main-content-container {
    position: absolute;
    bottom: 72px;
    top: 64px;
    left: 0;
    right: 0;
}

.modal-policy-content {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

@media (max-width: 628px) {
    .modal-container {
        left: 16px;
        right: 16px;
        top: 16px;
        bottom: 16px;
    }
}

@media (min-width: 628px) {
    .modal-container {
        width: 100%;
        left: 16px;
        right: 16px;
        top: 16px;
        bottom: 16px;
    }
}
