.block-ul ul li {
    white-space: pre-line;
    -ms-word-break: break-all;
    overflow-wrap: break-word;
}

.block-ul {
    padding-inline-start: 13px;
    padding-left: 13px;
}

.block-ul ul ul li:before {
    position: absolute;
    content: "\25A0";
    font-weight: bold;
    font-size: 7px;
    left: -13px;
    top: 9px;
}

.block-ul li:before {
    position: absolute;
    content: "\2022";
    font-weight: bold;
    left: -13px;
}

.block-ul ul li:before {
    position: absolute;
    content: "\26AC";
    font-weight: bold;
    font-size: 8px;
    left: -13px;
    top: 7px;
}

.block-ul li p {
    margin: 0;
    line-height: 1.5rem;
}

@media screen and (min-device-width: 460px) {
    .block-ul {
        padding-inline-start: 23px;
        padding-left: 23px;
     }
}
