* {
    font-family: 'Proxima Nova', Arial, sans-serif;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
}

div[id="root"] {
    min-height: 100vh;
}

@font-face {
    font-family: "bdc-icons";
    src:url("design/fonts/iconfont/bdc-icons.eot");
    src:url("design/fonts/iconfont/bdc-icons.eot?#iefix") format("embedded-opentype"),
    url("design/fonts/iconfont/bdc-icons.woff") format("woff"),
    url("design/fonts/iconfont/bdc-icons.ttf") format("truetype"),
    url("design/fonts/iconfont/bdc-icons.svg#bdc-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-Thin.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-Thin.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 100;
    font-style: normal;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-ThinIt.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-ThinItalic.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 100;
    font-style: italic;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-Light.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-Light.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 300;
    font-style: normal;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-LightIt.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-LightItalic.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 300;
    font-style: italic;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-Regular.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-Regular.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: normal;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-RegularIt.ttf') format('truetype');
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: italic;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-Medium.ttf') format('truetype');
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-style: normal;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-MediumIt.ttf') format('truetype');
    font-family: 'Proxima Nova';
    font-weight: 500;
    font-style: italic;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-Semibold.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-Semibold.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-style: normal;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-SemiboldIt.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-SemiboldIt.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-style: italic;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-Bold.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-Bold.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-style: normal;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-BoldIt.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-BoldItalic.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-style: italic;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-Extrabold.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-Extrabold.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 800;
    font-style: normal;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-ExtraboldIt.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-ExtraboldIt.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 800;
    font-style: italic;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-Black.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-Black.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 900;
    font-style: normal;
}

@font-face {
    src: url('design/fonts/proximanova/ProximaNova-BlackIt.ttf') format('truetype');
    src: url('design/fonts/proximanova/ProximaNovaW08-BlackItalic.woff') format('woff');
    font-family: 'Proxima Nova';
    font-weight: 900;
    font-style: italic;
}
