.search-bar-container {
    position: relative;
}

.search-bar-result-container {
    position: absolute;
    top: 55px;
    background-color: rgba(255,255,255,1);
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(147,147,147,0.5);
    z-index: 1;
}

.search-bar-hint {
    padding: 0 16px;
    font: 16px/18px 'Proxima Nova', sans-serif;
    letter-spacing: -0.11px;
}

input:-ms-input-placeholder {
    color: #999;
}

