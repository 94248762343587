.error-indicator-container {
    position: fixed;
    top: 0;
    left: 0;
    color: #A21523;
    right: 0;
    padding: 20px 20px 20px 30px;
    background-color: #FCF4F2;
    border: 1px solid #C55545;
    border-left: 8px solid #DA1D30;
    z-index: 1;
}

.error-indicator-margin {
    height: 64px;
}

.error-indicator-message:before{
    position: inline;
    content: 'e';
    top: 0;
    padding-right: 20px;
    font-family: 'bdc-icons';
    font-size: 25px;
    color: #DA1D30;
}

.error-indicator-message {
    display: flex;
    font: 500 14px/16px 'Proxima Nova', sans-serif;
    letter-spacing: 1.14px;
}

@media (max-width: 460px) {
    .error-indicator-container {
        padding: 10px 20px 10px 64px;
    }
}
