.modal-top-panel {
    padding: 23px 53px;
    background: linear-gradient(270deg, rgba(81,104,179,1) 0%, rgba(57,82,149,1) 100%);
}

.modal-top-panel-text {
    font: 500 16px/19px 'Proxima Nova';
    letter-spacing: -0.15px;
    color: rgba(255,255,255,1);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-top-panel-button {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
}

.modal-top-panel-button:hover {
    cursor: pointer;
}

.modal-top-panel-button:after {
    font-family: 'bdc-icons';
    content: 'c';
    color: rgba(255,255,255,1);
    font-size: 55px;
}
