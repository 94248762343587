.rich-text-container {
    font: 16px 'Proxima Nova', sans-serif;
    letter-spacing: -0.2px;
    padding: 1px 16px;
}

.rich-text-container-background {
    background-color: white;
    border: 1px solid rgba(240,240,241,1);
}

.rich-text-container ul {
    list-style: none;
}

.rich-text-container ul li {
    position: relative;
}

.rich-text-container ul li > ol > li:nth-of-type(n):before{
    content: "";
}

.rich-text-container ol li > ul > li:nth-of-type(n){
    list-style:none;
}

.rich-text-container-background-printable {
    background-color:white;
}
