.search-bar-result-item {
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.search-bar-result-item:hover {
    cursor: pointer;
    background-color: rgba(247,249,252,1);
}

.search-bar-result-item:not(:last-child) {
    border-bottom: 1px solid rgba(240,240,241,1);
}

.search-bar-result-item-text {
    font: 400 14px/18px "Proxima Nova", sans-serif;
    color: rgba(88,117,192,1);
    letter-spacing: 0.14px;
    vertical-align: middle;
}
