.inline-hyperlink {
    font: 700 16px/20px 'Proxima Nova';
    color: rgba(88,117,192,1);
    text-decoration: none;
}

.inline-hyperlink-printable {
    font: 700 16px/20px 'Proxima Nova';
    text-decoration: none;
}
